import Button from './Button';
import React from 'react';

const ButtonPrimary = ({ className = '', animation, ...args }) => {
  return (
    <Button
      // animate-bounce
      className={`${
        animation ? '' : 'hover:bg-indigo-600'
      } ttnc-ButtonPrimary bg-indigo-500 text-neutral-50 disabled:bg-opacity-70 ${className}`}
      animation={animation}
      {...args}
    />
  );
};

export default ButtonPrimary;
