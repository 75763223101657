import React from 'react';
import ButtonClose from './ButtonClose';
import Logo from '../../Logo';
import { Disclosure } from '@headlessui/react';
import ButtonPrimary from './ButtonPrimary';
import SocialsList from './SocialsList';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { v4 as uuidv4 } from 'uuid';
import { MyLink } from '../../MyLink';
import { signOut, useSession } from 'next-auth/react';
import { useDispatch } from 'react-redux';
import { updateModalstate } from '../../../slices/activateModal';
import { Icon1, Icon2, Icon22, Icon3, Icon4, Icon5 } from '../../Icons';
import { classNames } from '../../../utils';
import { useTranslation } from 'next-i18next';
import { CheckIcon, GlobeAltIcon } from '@heroicons/react/24/outline';
import { useRouter } from 'next/router';

function NavMobile({ onClickClose, setView }) {
  const { data: session, status } = useSession();
  const user = session?.token;
  const router = useRouter();
  const { pathname, asPath, query, locale } = router;
  const { t } = useTranslation('common', 'homepage');

  const esfaras = [
    {
      id: uuidv4(),
      href: 'https://blog.esfaras.de',
      name: 'Blog',
    },
    {
      id: uuidv4(),
      href: '/fuer-kunden',
      name: 'Für Kunden',
      translation: t('footer2'),
    },
    {
      id: uuidv4(),
      href: '/fuer-anbieter',
      name: 'Für Anbieter',
      translation: t('footer3'),
    },
    {
      id: uuidv4(),
      href: '/empfehlungsprogramm',
      name: 'Freunde werben',
      translation: t('footer4'),
    },
    {
      id: uuidv4(),
      href: '/anbieter-mitgliedschaft',
      name: 'Mitgliedschaft',
      translation: t('footer5'),
    },
    {
      id: uuidv4(),
      href: 'https://esfaras.de/ueber-uns',
      name: 'Über uns',
      translation: t('footer11'),
    },
    {
      id: uuidv4(),
      href: 'https://esfaras.de/pressebereich',
      name: 'Presse',
      translation: t('footer6'),
    },
    {
      id: uuidv4(),
      href: 'https://hilfe.esfaras.de/',
      name: 'Hilfe-Center',
      translation: t('helpcenter'),
    },
    {
      id: uuidv4(),
      href: '/kontaktiere-uns',
      name: 'Kontaktiere uns',
      translation: t('footer7'),
    },
  ];
  const StudiotypesMenu = [
    {
      id: uuidv4(),
      href: {
        pathname: '/tonstudios',
        query: {
          page: 1,
          filters: JSON.stringify({
            studioType: 'Heimstudio',
          }),
        },
      },
      name: 'Heimstudios',
      translation: t('home_studio'),
    },
    {
      id: uuidv4(),
      href: {
        pathname: '/tonstudios',
        query: {
          page: 1,
          filters: JSON.stringify({
            studioType: 'Medium Studio',
          }),
        },
      },
      name: 'Medium Studios',
      translation: t('medium_studio'),
    },
    {
      id: uuidv4(),
      href: {
        pathname: '/tonstudios',
        query: {
          page: 1,
          filters: JSON.stringify({
            studioType: 'Premium Studio',
          }),
        },
      },
      name: 'Premium Studios',
      translation: t('premium_studio'),
    },
  ];
  const onlineServicesMenu = [
    {
      id: uuidv4(),
      href: '/#',
      name: 'Musikproduktion & Song schreiben',
      translation: t('homepage:musicproduction_and_songwriting'),
      type: 'dropdown',
      children: [
        {
          id: uuidv4(),
          href: '/online-leistungen/Produzenten & Komponisten',
          name: 'Produzenten & Komponisten',
          translation: t('homepage:producers_and_composers'),
        },
        {
          id: uuidv4(),
          href: '/online-leistungen/Songwriter',
          name: 'Songwriter',
        },
        {
          id: uuidv4(),
          href: '/online-leistungen/Beats produzieren',
          name: 'Beats produzieren',
          translation: t('homepage:beats_production'),
        },
        {
          id: uuidv4(),
          href: '/online-leistungen/Sänger & Vokalisten',
          name: 'Sänger & Vokalisten',
          translation: t('homepage:singers_and_vocalists'),
        },
        {
          id: uuidv4(),
          href: '/online-leistungen/Studiomusiker',
          name: 'Studiomusiker',
          translation: t('homepage:session_musicians'),
        },
        {
          id: uuidv4(),
          href: '/online-leistungen/Erkennungsmelodien & Intros',
          name: 'Erkennungsmelodien & Intros',
          translation: t('homepage:jingles_and_intros'),
        },
      ],
    },
    {
      id: uuidv4(),
      href: '/#',
      name: 'Tontechnik & Postproduktion',
      translation: t('homepage:audio_engineering_and_post_production'),
      type: 'dropdown',
      children: [
        {
          id: uuidv4(),
          href: '/online-leistungen/Mischen & Mastern',
          name: 'Mischen & Mastern',
          translation: t('homepage:mixing_and_mastering'),
        },
        {
          id: uuidv4(),
          href: '/online-leistungen/Audiobearbeitung',
          name: 'Audiobearbeitung',
          translation: t('homepage:audio_editing'),
        },
        {
          id: uuidv4(),
          href: '/online-leistungen/Vocals bearbeiten',
          name: 'Vocals bearbeiten',
          translation: t('homepage:vocals_editing'),
        },
        {
          id: uuidv4(),
          href: '/online-leistungen/Audio-Logo & Sound-Branding',
          name: 'Audio-Logo & Sound-Branding',
          translation: t('homepage:audio_logo_and_sound_branding'),
        },
      ],
    },
    {
      id: uuidv4(),
      href: '/#',
      name: 'Voice Over & Streaming',
      type: 'dropdown',
      children: [
        {
          id: uuidv4(),
          href: '/online-leistungen/Voice Over',
          name: 'Voice Over',
        },
        {
          id: uuidv4(),
          href: '/online-leistungen/Podcast produzieren',
          name: 'Podcast produzieren',
          translation: t('homepage:beats_production'),
        },
        {
          id: uuidv4(),
          href: '/online-leistungen/Hörbuchproduktion',
          name: 'Hörbuchproduktion',
          translation: t('homepage:audiobook_production'),
        },
        {
          id: uuidv4(),
          href: '/online-leistungen/Audio- & Radiobewerbung',
          name: 'Audio- & Radiobewerbung',
          translation: t('homepage:audio_ads_production'),
        },
        {
          id: uuidv4(),
          href: '/online-leistungen/Sprachsynthese & KI',
          name: 'Sprachsynthese & KI',
          translation: t('homepage:voice_synthesis_and_ai'),
        },
      ],
    },
    {
      id: uuidv4(),
      href: '/#',
      name: 'Unterricht & Transkription',
      translation: t('homepage:lessons_and_transcirption'),
      type: 'dropdown',
      children: [
        {
          id: uuidv4(),
          href: '/online-leistungen/Online-Musikunterricht',
          name: 'Online-Musikunterricht',
          translation: t('homepage:online_music_lessons'),
        },
        {
          id: uuidv4(),
          href: '/online-leistungen/Musik-Transkription',
          name: 'Musik-Transkription',
          translation: t('homepage:music_transcription'),
        },
        {
          id: uuidv4(),
          href: '/online-leistungen/Musik- & Audio-Beratung',
          name: 'Musik- & Audio-Beratung',
          translation: t('homepage:music_and_audio_advice'),
        },
      ],
    },
  ];
  const NAVIGATION_DEMO = [
    {
      id: uuidv4(),
      href: '/',
      name: 'Startseite',
      translation: t('homepage'),
      type: 'single',
      icon: Icon1,
    },
    {
      id: uuidv4(),
      name: 'Lokale Leistungen',
      translation: t('local_services'),
      type: 'dropdown',
      icon: Icon3,
      children: [
        {
          id: uuidv4(),
          href: '/lokale-leistungen?filters=%7B"services"%3A"Aufnehmen"%7D&page=1',
          name: 'Aufnehmen',
          translation: t('homepage:recording'),
          type: 'single',
        },

        //
        {
          id: uuidv4(),
          href: '/lokale-leistungen?filters=%7B"services"%3A"Mischen"%7D&page=1',
          translation: t('homepage:mixing'),
          name: 'Mischen',
          type: 'single',
        },

        //
        {
          id: uuidv4(),
          href: '/lokale-leistungen?filters=%7B"services"%3A"Mastern"%7D&page=1',
          translation: t('homepage:mastering'),
          name: 'Mastern',
          type: 'single',
        },

        //
        {
          id: uuidv4(),
          href: '/lokale-leistungen?filters=%7B"services"%3A"Musikproduktion"%7D&page=1',
          translation: t('homepage:musicproduction'),
          name: 'Musikproduktion',
          type: 'single',
        },

        //
        {
          id: uuidv4(),
          href: '/lokale-leistungen?filters=%7B"services"%3A"Podcasts & Hörbücher"%7D&page=1',
          translation: t('homepage:podcasts_and_audiobooks'),
          name: 'Podcasts & Hörbücher',
          type: 'single',
        },

        //
        {
          id: uuidv4(),
          href: '/lokale-leistungen?filters=%7B"services"%3A"Studio mieten"%7D&page=1',
          translation: t('homepage:rent_studio'),
          name: 'Studio mieten',
          type: 'single',
        },

        //
        {
          id: uuidv4(),
          href: '/lokale-leistungen?filters=%7B"services"%3A"Proberaum mieten"%7D&page=1',
          translation: t('homepage:rent_rehearsal_room'),
          name: 'Proberaum mieten',
          type: 'single',
        },

        //
        {
          id: uuidv4(),
          href: '/lokale-leistungen?filters=%7B"services"%3A"Musikunterricht"%7D&page=1',
          translation: t('homepage:music_lessons'),
          name: 'Musikunterricht',
          type: 'single',
        },

        //
        {
          id: uuidv4(),
          href: '/lokale-leistungen?filters=%7B"services"%3A"Sonstige"%7D&page=1',
          translation: t('homepage:other'),
          name: 'Sonstige',
          type: 'single',
        },
      ],
    },
    {
      id: uuidv4(),
      name: 'Online Leistungen',
      translation: t('online_services'),
      type: 'dropdown',
      icon: Icon2,
      children: onlineServicesMenu,
    },
    {
      id: uuidv4(),
      name: 'Tonstudios',
      translation: t('recording_studios'),
      type: 'dropdown',
      icon: Icon4,
      children: StudiotypesMenu,
    },
    {
      id: uuidv4(),
      name: 'Allgemein',
      translation: t('common:footer1'),
      type: 'dropdown',
      icon: Icon5,
      // icon: () => <div className="ml-1 h-5 w-5"></div>,
      children: esfaras,
    },
  ];
  const data = NAVIGATION_DEMO;

  //
  const dispatch = useDispatch();

  const changeLanguage = (event) => {
    const locale = event;
    router.push({ pathname, query }, asPath, { locale: locale });
    onClickClose();
  };

  function handleSignInSignUp(set) {
    onClickClose();
    setView(set);
    setTimeout(() => {
      if (set == 'signup') dispatch(updateModalstate({ signup: true }));
      if (set == 'signin') dispatch(updateModalstate({ signin: true }));
    }, 400);
  }

  const _renderMenuChild = (item, nofollow) => {
    return (
      <ul className="nav-mobile-sub-menu pb-1 pl-6 text-base">
        {item.children?.map((i, index) => (
          <Disclosure key={i.href + index} as="li">
            {!i.children ? (
              <MyLink
                prefetch={false}
                rel={`${!!nofollow ? 'nofollow' : ''}`}
                href={i.href || undefined}
                className="mt-0.5 flex rounded-lg px-4 text-sm font-medium text-neutral-900 hover:bg-neutral-100">
                <span className={`py-2.5 pr-3 ${!i.children ? 'block w-full' : ''}`}>
                  {i.translation ? i.translation : i.name}
                </span>
              </MyLink>
            ) : (
              <Disclosure.Button className="mt-0.5 flex w-full rounded-lg px-4 text-sm font-medium text-neutral-900 hover:bg-neutral-100">
                <span className={`py-2.5 pr-3`}>{i.translation ? i.translation : i.name}</span>
                {i.children && (
                  <span className="flex flex-1 justify-end py-2.5">
                    <ChevronDownIcon className="ml-2 h-4 w-4 text-neutral-500" aria-hidden="true" />
                  </span>
                )}
              </Disclosure.Button>
            )}
            {i.children && <Disclosure.Panel>{_renderMenuChild(i, true)}</Disclosure.Panel>}
          </Disclosure>
        ))}
      </ul>
    );
  };

  //Single
  const _renderItem = (item, index) => {
    return (
      <Disclosure key={item.id} as="li" className="text-neutral-900">
        {!item.children ? (
          <MyLink
            prefetch={false}
            className={classNames(
              item.icon && 'items-center gap-2',
              'flex w-full rounded-lg px-4 text-sm font-medium uppercase tracking-wide hover:bg-neutral-100'
            )}
            href={item.href || undefined}>
            {item.icon && (
              <item.icon
                className={`h-6 w-6 fill-black transition duration-300 group-hover:scale-125 group-hover:drop-shadow-sm`}
              />
            )}
            <span className={`py-2.5 pr-3`}>{item.translation ? item.translation : item.name}</span>
          </MyLink>
        ) : (
          <Disclosure.Button
            className={classNames(
              item.icon && 'items-center gap-2',
              'flex w-full rounded-lg px-4 text-sm font-medium uppercase tracking-wide hover:bg-neutral-100'
            )}>
            {item.icon && (
              <item.icon
                className={`h-6 w-6 fill-black transition duration-300 group-hover:scale-125 group-hover:drop-shadow-sm`}
              />
            )}
            <span className={`py-2.5 pr-3 ${!item.children ? 'block w-full' : ''}`}>
              {item.translation ? item.translation : item.name}
            </span>
            {item.children && (
              <span className="flex flex-1">
                <span className="flex flex-1 items-center justify-end py-2.5 ">
                  <ChevronDownIcon className="ml-2 h-4 w-4 text-neutral-500" aria-hidden="true" />
                </span>
              </span>
            )}
          </Disclosure.Button>
        )}
        {/*  */}
        {/* childs */}
        {item.children && <Disclosure.Panel>{_renderMenuChild(item, item.name !== 'Allgemein')}</Disclosure.Panel>}
      </Disclosure>
    );
  };

  return (
    <>
      <div className="h-screen w-full transform divide-y-2 divide-neutral-100 overflow-y-auto bg-white py-2 pb-48 shadow-lg ring-1 transition">
        <div className="px-5 py-6">
          <Logo />
          <div className="mt-5 flex flex-col text-sm text-neutral-700  ">
            <span>{t('mobilenav_desc')}</span>
            <div className="mt-4 flex items-center justify-between">
              <SocialsList itemClass="w-9 h-9 flex items-center justify-center rounded-full bg-neutral-100 text-xl" />
            </div>
          </div>
          <span className="absolute right-2 top-2 p-1">
            <ButtonClose onClick={onClickClose} />
          </span>
        </div>
        <ul className="flex flex-col space-y-1 px-2 py-6">{data.map(_renderItem)}</ul>
        {/* {user && (
          <ul className="flex flex-col space-y-1 px-2 py-6">
            <MyLink
              prefetch={false}
              className="flex w-full rounded-lg px-4 py-2.5 pr-3 text-sm font-medium uppercase tracking-wide hover:bg-neutral-100"
              href="/dashboard">
              Dashboard
            </MyLink>
            <MyLink
              prefetch={false}
              href="/dashboard/nachrichten"
              className="flex w-full rounded-lg px-4 py-2.5 pr-3 text-sm font-medium uppercase tracking-wide hover:bg-neutral-100">
              <Badge
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                color="error"
                badgeContent={unreadCount?.msgs}
                invisible={!unreadCount?.msgs}>
                <h4 className="mr-2.5">Nachrichten</h4>
              </Badge>
            </MyLink>
            <MyLink
              prefetch={false}
              className="flex w-full rounded-lg px-4 py-2.5 pr-3 text-sm font-medium uppercase tracking-wide hover:bg-neutral-100"
              href="/dashboard/merkliste">
              Merkliste
            </MyLink>
            <MyLink
              prefetch={false}
              className="flex w-full rounded-lg px-4 py-2.5 pr-3 text-sm font-medium uppercase tracking-wide hover:bg-neutral-100"
              href="/dashboard/hinzufuegen">
              Hinzufügen
            </MyLink>
            {settingsLinks.map(_renderItem)}
          </ul>
        )} */}
        <div className="flex flex-col space-y-1 px-2 py-6">
          <Disclosure as="div" className="text-neutral-900">
            <Disclosure.Button
              className={classNames(
                'items-center gap-2',
                'flex w-full rounded-lg px-4 text-sm font-medium uppercase tracking-wide hover:bg-neutral-100'
              )}>
              <GlobeAltIcon
                className={`h-6 w-6 transition duration-300 group-hover:scale-125 group-hover:drop-shadow-sm`}
              />
              <span className={`py-2.5 pr-3`}>{t('language')}</span>
              <span className="flex flex-1">
                <span className="flex flex-1 items-center justify-end py-2.5 ">
                  <ChevronDownIcon className="ml-2 h-4 w-4 text-neutral-500" aria-hidden="true" />
                </span>
              </span>
            </Disclosure.Button>
            <Disclosure.Panel>
              <ul className="nav-mobile-sub-menu pb-1 pl-6 text-base">
                <Disclosure as="li">
                  <span
                    onClick={() => changeLanguage('de')}
                    className="mt-0.5 flex cursor-pointer rounded-lg px-4 text-sm font-medium text-neutral-900 hover:bg-neutral-100">
                    <span className={`'block relative w-full py-2.5 pr-3`}>
                      Deutsch
                      <span
                        className={classNames(
                          locale == 'de' ? 'text-black' : 'hidden text-black',
                          'absolute inset-y-0 right-0 flex items-center pr-10'
                        )}>
                        <CheckIcon className="h-4 w-4 stroke-2" aria-hidden="true" />
                      </span>
                    </span>
                  </span>
                </Disclosure>
                <Disclosure as="li">
                  <span
                    onClick={() => changeLanguage('en')}
                    className="mt-0.5 flex cursor-pointer rounded-lg px-4 text-sm font-medium text-neutral-900 hover:bg-neutral-100">
                    <span className={`'block relative w-full py-2.5 pr-3`}>
                      English
                      <span
                        className={classNames(
                          locale == 'en' ? 'text-black' : 'hidden text-black',
                          'absolute inset-y-0 right-0 flex items-center pr-10'
                        )}>
                        <CheckIcon className="h-4 w-4 stroke-2" aria-hidden="true" />
                      </span>
                    </span>
                  </span>
                </Disclosure>
              </ul>
            </Disclosure.Panel>
          </Disclosure>
        </div>
        {!user ? (
          <div className="flex items-center justify-between px-5 py-6">
            <ButtonPrimary className="inline-block" onClick={() => handleSignInSignUp('signup')}>
              {t('signup')}
            </ButtonPrimary>
            <ButtonPrimary className="inline-block !animate-none" onClick={() => handleSignInSignUp('signin')}>
              {t('signin')}
            </ButtonPrimary>
          </div>
        ) : (
          <div className="px-2 py-6">
            <button
              onClick={() => {
                signOut();
              }}
              className={classNames(
                'items-center gap-2',
                'flex w-full rounded-lg px-4 text-sm font-medium uppercase tracking-wide hover:bg-neutral-100'
              )}>
              <Icon22
                className={`h-6 w-6 fill-black transition duration-300 group-hover:scale-125 group-hover:drop-shadow-sm`}
              />
              <span className={`w-full} block py-2.5 pr-3`}>{t('signout')}</span>
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default NavMobile;
