import React, { useState, Fragment, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import NavMobile from './Navigation/NavMobile';
import { useRouter } from 'next/router';
import { Icon5 } from '../Icons';

// header drawer menu comp

const MenuBar = ({ unreadCount }) => {
  const [isVisable, setIsVisable] = useState(false);
  const [view, setView] = useState('');
  const router = useRouter();
  const pathname = router.asPath;

  useEffect(() => {
    setIsVisable(false);
  }, [pathname]);

  useEffect(() => {
    setIsVisable(false);
  }, [view]);

  const handleOpenMenu = () => setIsVisable(true);
  const handleCloseMenu = () => {
    setIsVisable(false);
  };

  const renderContent = () => {
    return (
      <>
        <Dialog as="div" open={isVisable} className={`relative z-50 overflow-hidden`} onClose={handleCloseMenu}>
          <Dialog.Overlay className={`fixed inset-0 z-10 bg-black/60 backdrop-blur-sm`} />

          <div className="fixed inset-0 z-20">
            <div className="flex min-h-full justify-end ">
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden transition-all ">
                <NavMobile onClickClose={handleCloseMenu} setView={setView} unreadCount={unreadCount} />
              </Dialog.Panel>
            </div>
          </div>
        </Dialog>
      </>
    );
  };

  return (
    <>
      <button
        aria-label="Nutzermenü öffnen"
        onClick={handleOpenMenu}
        className={`group flex rounded-full bg-indigo-500 px-2 py-2 transition duration-200 xl:px-2.5 xl:py-2.5`}>
        <Icon5
          className={`h-7 w-7 fill-white transition duration-300 group-hover:scale-125 group-hover:drop-shadow-sm`}
        />
      </button>

      {isVisable && renderContent()}
    </>
  );
};

export default MenuBar;
