import React from 'react';
import { MyLink } from '../../MyLink';
import { BsInstagram, BsFacebook, BsTiktok, BsYoutube, BsTwitterX } from 'react-icons/bs';

const socialsDemo = [
  { name: 'Instagram', icon: BsInstagram, href: 'https://www.instagram.com/esfaras.de/' },
  { name: 'TikTok', icon: BsTiktok, href: 'https://www.tiktok.com/@oziesfaras?_t=8mCrleSd9kH&_r=1' },
  { name: 'Youtube', icon: BsYoutube, href: 'https://www.youtube.com/channel/UCkufLg4U4RxfLVQ27s5B4bg' },
  { name: 'X', icon: BsTwitterX, href: 'https://twitter.com/esfaras' },
  { name: 'Facebook', icon: BsFacebook, href: 'https://www.facebook.com/esfaras.de/' },
];

const SocialsList = ({ className = '', itemClass = 'block', socials = socialsDemo }) => {
  return (
    <nav
      className={`nc-SocialsList flex space-x-2.5 text-2xl text-neutral-6000   ${className}`}
      data-nc-id="SocialsList">
      {socials.map((item, i) => (
        <MyLink
          prefetch={false}
          key={i}
          className={`${itemClass}`}
          href={item.href}
          target="_blank"
          rel="noopener nofollow">
          <item.icon className=""></item.icon>
          <span className="sr-only">{item.name}</span>
        </MyLink>
      ))}
    </nav>
  );
};

export default SocialsList;
