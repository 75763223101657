import { useEffect } from 'react';

export const isMultiple = (value) => (value === 0 || value > 1 || value === undefined ? 's' : '');

export const isMultipleE = (value) => (value === 0 || value > 1 || value === undefined ? 'e' : '');

export const isMultipleN = (value) => (value === 0 || value > 1 || value === undefined ? 'n' : '');

export const isEven = (idx) => idx % 2 !== 0;

export const firstCapitalize = (value) => value?.charAt(0)?.toUpperCase() + value?.slice(1);

export const truncateString = (str, maxLength) => {
  if (str.length > maxLength) {
    return str.slice(0, maxLength) + '...';
  }
  return str;
};

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
};

export const formatURL = (url) => {
  if (!url) return url;
  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url;
  } else {
    return `https://${url}`;
  }
};

export const fromCentsToDecimal = function fromCentsToDecimal(val = 0) {
  const price = val == 0 ? 0 : val / 100;
  return price.toString() ?? '0';
};

export const scrolltotop = function scrolltotop() {
  setTimeout(() => {
    // Function to scroll all scrollable elements to the top
    // Select all elements you suspect might be scrollable
    const elements = document.querySelectorAll('div, section, main'); // Extend this selector as needed

    // Filter and scroll each element
    elements.forEach((el) => {
      if (isScrollable(el)) {
        el.scrollTop = 0;
      }
    });

    // Helper function to determine if an element is scrollable
    function isScrollable(element) {
      const computedStyle = getComputedStyle(element);
      return (
        (computedStyle.overflowY === 'scroll' || computedStyle.overflowY === 'auto') &&
        element.scrollHeight > element.clientHeight
      );
    }
  }, 20);
};

export const roundHalf = (num) => {
  return Math.round(num * 2) / 2;
};
export const roundHalfWithPoint = (num) => {
  return (Math.round(num * 10) / 10).toFixed(1);
};

export const getCurrencySymbol = (locale, currency) => {
  if (currency) {
    return (0)
      .toLocaleString(locale, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, '')
      .trim();
  }
};

export const wordCapitalize = (value) => {
  const splitted = value.split(' ');
  return splitted
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(' ');
};

export const isEmptyObject = (obj) => {
  return JSON.stringify(obj) === '{}';
};

export const isEmptyArray = (arr) => {
  if (!arr) return true;
  if (!arr.length) return true;
  if (arr === '{}') return true;
  else return false;
};
//only for not nested Objects (!arrs, !obj)
export const areSameObjects = function (obj1, obj2) {
  const obj1Length = Object.keys(obj1).length;
  const obj2Length = Object.keys(obj2).length;
  if (obj1Length === obj2Length) {
    // eslint-disable-next-line no-prototype-builtins
    return Object.keys(obj1).every((key) => obj2.hasOwnProperty(key) && obj2[key] === obj1[key]);
  }
  return false;
};

// ToDO: Use Compound operator for AtlasSearch and turn (metadata, speaks, livesin, deliverytime, price) stages to a combined search stage
export const createAggregationPipelineForOnlineServices = async (filtersObj) => {
  let pipeline = [
    {
      $lookup: {
        from: 'digitalservicecategories',
        localField: 'category',
        foreignField: '_id',
        as: 'category',
      },
    },
    {
      $set: {
        category: {
          $arrayElemAt: ['$category', 0],
        },
      },
    },
    {
      $lookup: {
        from: 'digitalservicesubcategories',
        localField: 'subcategory',
        foreignField: '_id',
        as: 'subcategory',
      },
    },
    {
      $set: {
        subcategory: {
          $arrayElemAt: ['$subcategory', 0],
        },
      },
    },
    {
      $lookup: {
        from: 'studiolistings',
        localField: 'studio',
        foreignField: '_id',
        as: 'studio',
      },
    },
    {
      $set: {
        studio: {
          $arrayElemAt: ['$studio', 0],
        },
      },
    },
    {
      $lookup: {
        from: 'otherprofiles',
        localField: 'profile',
        foreignField: '_id',
        pipeline: [
          {
            $lookup: {
              from: 'profileroles',
              localField: 'role',
              foreignField: '_id',
              as: 'role',
            },
          },
        ],
        as: 'profile',
      },
    },
    {
      $set: {
        profile: {
          $arrayElemAt: ['$profile', 0],
        },
      },
    },
    {
      $set: {
        'profile.role': {
          $arrayElemAt: ['$profile.role', 0],
        },
      },
    },
    {
      $addFields: {
        pricingPackage1: {
          $toInt: { $arrayElemAt: ['$packages.price', 0] },
        },
        deliveryTimePackage1: {
          $toInt: { $arrayElemAt: ['$packages.deliverytime', 0] },
        },
      },
    },
    {
      $lookup: {
        from: 'users',
        let: { user_id: '$user' }, // Variable for use in the pipeline
        pipeline: [
          {
            $match: {
              $expr: { $eq: ['$_id', '$$user_id'] },
            },
          },
          {
            $project: {
              _id: 0,
              userProviderSubscriptionId: { $ifNull: ['$userProviderSubscription.id', null] }, // Use $ifNull to handle undefined userProviderSubscription
            },
          },
        ],
        as: 'user_tmp',
      },
    },
    {
      $addFields: {
        user: {
          $arrayElemAt: ['$user_tmp', 0], // Convert the resulting array to an object
        },
      },
    },
    {
      $project: {
        user_tmp: 0, // Remove the temporary field
      },
    },
  ];
  //
  if (filtersObj.category) {
    pipeline.push({
      $match: {
        $expr: { $eq: ['$category._id', { $toObjectId: filtersObj.category }] },
      },
    });
  }
  if (filtersObj.subcategory) {
    pipeline.push({
      $match: {
        $expr: { $eq: ['$subcategory._id', { $toObjectId: filtersObj.subcategory }] },
      },
    });
  }
  // add metadata path too.
  // score: <options>,
  // synonyms: "<synonyms-mapping-name>"
  // $search: {
  //   index: "searchOnlineServiceTitle",
  //   text: {
  //     query: "pop",
  //     path: {
  //       wildcard: "*"
  //     }
  //   }
  // }
  if (filtersObj.search) {
    pipeline.unshift({
      $search: {
        index: 'searchOnlineServiceTitle', // optional, defaults to "default"
        text: {
          query: filtersObj.search,
          path: ['title', 'tags', 'metadata'],
          fuzzy: {},
        },
      },
    });
  }
  if (filtersObj.priceMin || filtersObj.priceMax) {
    pipeline.push({
      $match: {
        pricingPackage1: { $gte: filtersObj.priceMin, $lte: filtersObj.priceMax },
      },
    });
  }
  if (filtersObj.deliveryTime) {
    pipeline.push({
      $match: {
        deliveryTimePackage1: { $lte: filtersObj.deliveryTime },
      },
    });
  }
  if (filtersObj.metadata) {
    pipeline.push({
      $match: {
        metadata: {
          $in: filtersObj.metadata,
        },
      },
    });
  }
  if (filtersObj.speaks) {
    pipeline.push({
      $match: {
        $or: [
          {
            'studio.studioLanguages': {
              $in: filtersObj.speaks,
            },
          },
          {
            'profile.languages': {
              $in: filtersObj.speaks,
            },
          },
        ],
      },
    });
  }
  if (filtersObj.livesIn) {
    pipeline.push({
      $match: {
        $and: [
          {
            $or: [
              {
                'studio.studioLocation.country': {
                  $in: filtersObj.livesIn,
                },
              },
              {
                'profile.location.country': {
                  $in: filtersObj.livesIn,
                },
              },
            ],
          },
        ],
      },
      // {
      // Deutschland, Deutsch, Schweizerisch
      // Deutschland, Schweiz, Schweizerisch
      // Deutschland
      // Schweizerisch
      //   'studio.studioLocation.country': {
      //     $in: ['Schweiz', 'b'],
      //   },
      //   //maybe set new Field combining studio, profile and metadata data into one arr.
      //   //studiolocation and metadata are inside sellerOptions
      //   //profileLocation and metadata are inside sellerOptions
      // },
    });
  }
  if (filtersObj.sortBy === 'Neu hinzugefügt') {
    pipeline.push({
      $sort: {
        _id: -1,
      },
    });
  }

  return { pipeline };
};

export const createAggregationPipelineForStudioServices = async (filtersObj) => {
  let centerCoordsAfterSearch;
  let bboxinitial;
  let pipeline = [
    {
      $lookup: {
        from: 'adminstudioservicecategories',
        localField: 'service',
        foreignField: '_id',
        as: 'service',
      },
    },
    {
      $lookup: {
        from: 'users',
        let: { user_id: '$user' }, // Variable for use in the pipeline
        pipeline: [
          {
            $match: {
              $expr: { $eq: ['$_id', '$$user_id'] },
            },
          },
          {
            $project: {
              _id: 0,
              userProviderSubscriptionId: { $ifNull: ['$userProviderSubscription.id', null] }, // Use $ifNull to handle undefined userProviderSubscription
            },
          },
        ],
        as: 'user_tmp',
      },
    },
    {
      $addFields: {
        user: {
          $arrayElemAt: ['$user_tmp', 0], // Convert the resulting array to an object
        },
      },
    },
    {
      $project: {
        user_tmp: 0, // Remove the temporary field
      },
    },
    {
      $set: {
        service: {
          $arrayElemAt: ['$service', 0],
        },
      },
    },
    {
      $lookup: {
        from: 'studiolistings',
        localField: 'studio',
        foreignField: '_id',
        as: 'studio',
      },
    },
    {
      $set: {
        studio: {
          $arrayElemAt: ['$studio', 0],
        },
      },
    },
    {
      $lookup: {
        from: 'otherprofiles',
        localField: 'profile',
        foreignField: '_id',
        pipeline: [
          {
            $lookup: {
              from: 'profileroles',
              localField: 'role',
              foreignField: '_id',
              as: 'role',
            },
          },
        ],
        as: 'profile',
      },
    },
    {
      $set: {
        profile: {
          $arrayElemAt: ['$profile', 0],
        },
      },
    },
    {
      $set: {
        'profile.role': {
          $arrayElemAt: ['$profile.role', 0],
        },
      },
    },
    {
      $addFields: {
        'pricing.pricingHour': {
          $toInt: '$pricing.pricingHour',
        },
      },
    },
    {
      $addFields: {
        'pricing.pricingDay': {
          $toInt: '$pricing.pricingDay',
        },
      },
    },
    {
      $addFields: {
        'pricing.pricingWeek': {
          $toInt: '$pricing.pricingWeek',
        },
      },
    },
    {
      $addFields: {
        'pricing.pricingMonth': {
          $toInt: '$pricing.pricingMonth',
        },
      },
    },
    {
      $sort: {
        _id: -1,
      },
    },
  ];

  if (filtersObj.services) {
    pipeline.push({
      $match: {
        'service.name': filtersObj.services,
      },
    });
  }

  if (filtersObj.pricing) {
    pipeline.push({
      $match: {
        'pricing.pricingHour': {
          $gte: filtersObj.pricing.min,
          $lte: filtersObj.pricing.max,
        },
        // maxGuests: {
        //   $gte: 0,
        // },
      },
    });
  }

  if (filtersObj.guest) {
    const guests = Number(filtersObj.guest);

    pipeline.push({
      $match: {
        maxGuests: {
          $gte: guests,
        },
      },
    });
  }

  if (filtersObj.studioType) {
    const studioType = `${filtersObj.studioType}`;

    pipeline.push({
      $match: {
        'studio.studiotype': studioType,
      },
    });
  }

  if (filtersObj.features && filtersObj.features.length > 0) {
    pipeline.push({
      $match: {
        'studio.locationFeatures': {
          $in: filtersObj.features,
        },
      },
    });
  }

  if (filtersObj.allowed && filtersObj.allowed.length > 0) {
    pipeline.push({
      $match: {
        'studio.studioRules': {
          $in: filtersObj.allowed,
        },
      },
    });
  }

  if (filtersObj.languages && filtersObj.languages.length > 0) {
    pipeline.push({
      $match: {
        'studio.studioLanguages': {
          $in: filtersObj.languages,
        },
      },
    });
  }

  if (filtersObj.sortBy) {
    const sortByFilter = {
      'Preis niedrig-hoch': {
        $sort: {
          'pricing.pricingHour': 1,
          'pricing.pricingDay': 1,
          'pricing.pricingWeek': 1,
          'pricing.pricingMonth': 1,
        },
      },
      'Preis hoch-niedrig': {
        $sort: {
          'pricing.pricingHour': -1,
          'pricing.pricingDay': -1,
          'pricing.pricingWeek': -1,
          'pricing.pricingMonth': -1,
        },
      },
    };

    if (sortByFilter[filtersObj.sortBy]) {
      let oldSort = pipeline.find((item) => item.$sort);
      let newSort = (Object.keys(sortByFilter[filtersObj.sortBy])[0] = Object.values(
        sortByFilter[filtersObj.sortBy]
      )[0]);

      pipeline = pipeline.map((keys) => {
        let isSort = Object.keys(keys).includes('$sort');
        return isSort ? { $sort: { ...newSort, ...oldSort.$sort } } : { ...keys };
      });
    }
  }

  if (filtersObj.location) {
    const fetchLocationQueryCoordinates = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${filtersObj.location}.json?language=de&limit=1&bbox=5.73,45.73,17.23,54.98&access_token=${process.env.mapbox_key}`
    );
    const getLocationQueryCoordinates = await fetchLocationQueryCoordinates.json();
    const locationCoordinates = getLocationQueryCoordinates.features[0];

    let distance;
    if (filtersObj.radius) {
      if (filtersObj.radius === '5 km') distance = 5;
      if (filtersObj.radius === '15 km') distance = 15;
      if (filtersObj.radius === '50 km') distance = 50;
      if (filtersObj.radius === '250 km') distance = 250;
    } else {
      distance = 25;
    }

    const radiusToSearchIn = distance / 6378.1;

    if (locationCoordinates) {
      const { bbox } = locationCoordinates;
      centerCoordsAfterSearch = locationCoordinates.center;
      bboxinitial = bbox;
    
      // Calculate the bounding box or centerSphere coordinates once, as needed
      let geoQuery;
      if (locationCoordinates.bbox) {
        const bboxCoords = [
          [bbox[0], bbox[1]],
          [bbox[2], bbox[3]],
        ];
        geoQuery = { $box: bboxCoords };
      } else {
        const radiusToSearchIn = distance / 6378.1; // Assuming 'distance' is defined and calculated as before
        geoQuery = { $centerSphere: [locationCoordinates.center, radiusToSearchIn] };
      }


      // Push a conditional $match stage to the pipeline
  pipeline.push({
    $match: {
      $or: [
        {
          $and: [
            { 'differentLocation.status': "true" },
            {
              'differentLocation.location.geolocation': {
                $geoWithin: geoQuery
              }
            }
          ]
        },
        {
          $and: [
            {
              $or: [
                { 'differentLocation.status': "false" },
                { 'differentLocation': { $exists: false } }
              ]
            },           
            {
              $or: [
                {
                  'studio.studioLocation.geolocation': {
                    $geoWithin: geoQuery
                  },
                },
                {
                  'profile.location.geolocation': {
                    $geoWithin: geoQuery
                  },
                }
              ]
            }
          ]
        }
      ]
    }
  });
  }
    // if (locationCoordinates) {
    //   if (locationCoordinates.bbox) {
    //     const { bbox } = locationCoordinates;

    //     const bboxCoords = [
    //       [bbox[0], bbox[1]],
    //       [bbox[2], bbox[3]],
    //     ];
    //     bboxinitial = bbox;
    //     centerCoordsAfterSearch = locationCoordinates.center;

    //     pipeline.push({
    //       $match: {
    //         $or: [
    //           {
    //             'differentLocation.location.geolocation': {
    //               $geoWithin: {
    //                 $box: bboxCoords,
    //               },
    //             },
    //           },
    //           {
    //             'studio.studioLocation.geolocation': {
    //               $geoWithin: {
    //                 $box: bboxCoords,
    //               },
    //             },
    //           },
    //           {
    //             'profile.location.geolocation': {
    //               $geoWithin: {
    //                 $box: bboxCoords,
    //               },
    //             },
    //           },
    //         ],
    //       },
    //     });
    //   } else {
    //     const { bbox } = locationCoordinates;

    //     centerCoordsAfterSearch = locationCoordinates.center;
    //     bboxinitial = bbox;
    //     pipeline.push({
    //       $match: {
    //         $or: [
    //           {
    //             'differentLocation.location.geolocation': {
    //               $geoWithin: {
    //                 $centerSphere: [locationCoordinates.center, radiusToSearchIn],
    //               },
    //             },
    //           },
    //           {
    //             'studio.studioLocation.geolocation': {
    //               $geoWithin: {
    //                 $centerSphere: [locationCoordinates.center, radiusToSearchIn],
    //               },
    //             },
    //           },
    //           {
    //             'profile.location.geolocation': {
    //               $geoWithin: {
    //                 $centerSphere: [locationCoordinates.center, radiusToSearchIn],
    //               },
    //             },
    //           },
    //         ],
    //       },
    //     });
    //   }
    // }
  }

  return { pipeline, centerCoordsAfterSearch, bboxinitial };
};

export const createAggregationPipelineForStudios = async (filtersObj) => {
  let centerCoordsAfterSearch;
  let bboxinitial;
  let pipeline = [
    {
      $lookup: {
        from: 'users',
        let: { user_id: '$user' }, // Variable for use in the pipeline
        pipeline: [
          {
            $match: {
              $expr: { $eq: ['$_id', '$$user_id'] },
            },
          },
          {
            $project: {
              _id: 0,
              userProviderSubscriptionId: { $ifNull: ['$userProviderSubscription.id', null] }, // Use $ifNull to handle undefined userProviderSubscription
            },
          },
        ],
        as: 'user_tmp',
      },
    },
    {
      $addFields: {
        user: {
          $arrayElemAt: ['$user_tmp', 0], // Convert the resulting array to an object
        },
      },
    },
    {
      $project: {
        user_tmp: 0, // Remove the temporary field
      },
    },
    {
      $sort: {
        _id: -1,
      },
    },
  ];
  if (filtersObj.studioType) {
    const studioType = `${filtersObj.studioType}`;
    pipeline.push({
      $match: {
        studiotype: studioType,
      },
    });
  }

  if (filtersObj.guest) {
    const guests = Number(filtersObj.guest);

    pipeline.push({
      $match: {
        maxGuests: {
          $gte: guests,
        },
      },
    });
  }

  if (filtersObj.features && filtersObj.features.length > 0) {
    pipeline.push({
      $match: {
        locationFeatures: {
          $in: filtersObj.features,
        },
      },
    });
  }

  if (filtersObj.allowed && filtersObj.allowed.length > 0) {
    pipeline.push({
      $match: {
        studioRules: {
          $in: filtersObj.allowed,
        },
      },
    });
  }

  if (filtersObj.languages && filtersObj.languages.length > 0) {
    pipeline.push({
      $match: {
        studioLanguages: {
          $in: filtersObj.languages,
        },
      },
    });
  }

  // if (filtersObj.sortBy) {
  //   const sortByFilter = {
  //     'Newly added': {
  //       $sort: {
  //         createdAt: -1,
  //       },
  //     },
  //   };
  //   if (sortByFilter[filtersObj.sortBy]) {
  //     pipeline.push(sortByFilter[filtersObj.sortBy]);
  //   }
  // }

  if (filtersObj.location) {
    const fetchLocationQueryCoordinates = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${filtersObj.location}.json?language=de&limit=1&bbox=5.866315,47.270238,15.041832,55.1286491&access_token=${process.env.mapbox_key}`
    );
    const getLocationQueryCoordinates = await fetchLocationQueryCoordinates.json();
    const locationCoordinates = getLocationQueryCoordinates.features[0];

    let distance;
    if (filtersObj.radius) {
      if (filtersObj.radius === '5 km') distance = 5;
      if (filtersObj.radius === '15 km') distance = 15;
      if (filtersObj.radius === '50 km') distance = 50;
      if (filtersObj.radius === '250 km') distance = 250;
    } else {
      distance = 25;
    }

    const radiusToSearchIn = distance / 6378.1;

    if (locationCoordinates) {
      if (locationCoordinates.bbox) {
        const { bbox } = locationCoordinates;

        const bboxCoords = [
          [bbox[0], bbox[1]],
          [bbox[2], bbox[3]],
        ];
        bboxinitial = bbox;
        centerCoordsAfterSearch = locationCoordinates.center;

        pipeline.push({
          $match: {
            'studioLocation.geolocation': {
              $geoWithin: {
                $box: bboxCoords,
              },
            },
          },
        });
      } else {
        const { bbox } = locationCoordinates;

        bboxinitial = bbox;
        centerCoordsAfterSearch = locationCoordinates.center;
        pipeline.push({
          $match: {
            'studioLocation.geolocation': {
              $geoWithin: {
                $centerSphere: [locationCoordinates.center, radiusToSearchIn],
              },
            },
          },
        });
      }
    }
  }

  return { pipeline, centerCoordsAfterSearch, bboxinitial };
};

export function diffInTime(start, end) {
  if (!start || !end) return;

  start = start.split(':');
  end = end.split(':');

  var startDate = new Date(0, 0, 0, start[0], start[1], 0);
  var endDate = new Date(0, 0, 0, end[0], end[1], 0);
  var diff = endDate.getTime() - startDate.getTime();

  // hrs
  var hours = Math.floor(diff / 1000 / 60 / 60);
  // mins
  var diffmins = diff - hours * 1000 * 60 * 60;
  var mins = Math.floor(diffmins / 1000 / 60);
  // unfloored hours+mins
  // var decimalDiff = diff / 1000 / 60 / 60;
  var decimalDiff = Math.round((diff / 1000 / 60 / 60) * 100) / 100;
  // If using time pickers with 24 hours format, add the below line get exact hours
  if (hours < 0) hours = hours + 24;
  if (decimalDiff < 0) decimalDiff = Math.round((decimalDiff + 24) * 100) / 100;
  // return hours;
  return decimalDiff;
}

export function dateDiffInDays(startDate, endDate) {
  const a = new Date(startDate);
  const b = new Date(endDate);

  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY) + 1;
}

export const setHourDurationTimestamp = (formData) => {
  //Hour = startime (string), endtime (string), date (date)
  //Day = stardate (date), enddate (date)

  // ToDo: if start is 14:40 but end is 14:30 its 23 hrs 50.. Maybe do 14,40 - 14,30 in line 684
  const [startHours, startMinutes] = [formData?.startTime?.slice(0, 2), formData?.startTime?.slice(3, 5)];
  const [endHours, endMinutes] = [formData?.endTime?.slice(0, 2), formData?.endTime?.slice(3, 5)];
  const startDate = new Date(formData?.date);
  startDate.setHours(startHours);
  startDate.setMinutes(startMinutes);
  startDate.setSeconds('00');
  let endDate = new Date(formData?.date);
  // if endtime is in next day
  if (Number(startHours) - Number(endHours) > 0) {
    endDate.setDate(endDate.getDate() + 1);
  }
  endDate.setHours(endHours);
  endDate.setMinutes(endMinutes);
  endDate.setSeconds('00');

  return { startDate, endDate };
};

export const setDayDurationTimestamp = (formData) => {
  const startDate = formData.startDate;
  const endDate = formData.endDate;
  return { startDate, endDate };
};

export const converSelectedDateToString = ([startDate, endDate]) => {
  const dateString =
    (startDate?.toLocaleDateString('de-DE', {
      month: 'short',
      day: '2-digit',
    }) || '') +
    (endDate
      ? ' - ' +
        endDate?.toLocaleDateString('de-DE', {
          month: 'short',
          day: '2-digit',
        })
      : '');
  return dateString;
};

export const convertNumbThousand = (x) => {
  if (!x) {
    return '0';
  }
  return x.toLocaleString('de-DE');
};

export const twFocusClass = (hasRing = false) => {
  if (!hasRing) {
    return 'focus:outline-none';
  }
  return 'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000  ';
};

export function useOutsideAlerter(ref, handleClickOutsideCallback) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      event.stopPropagation();
      if (ref.current && !ref.current.contains(event.target)) {
        handleClickOutsideCallback();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]); // eslint-disable-line react-hooks/exhaustive-deps
}

export function isInViewport(element) {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

// check if subscription is enabled
export function useIsSubscriptionValid(subscription) {
  const isPaid = subscription?.status === 'paid' ? true : false;
  const isPeriodValid = new Date(subscription?.periodEndsAt).getTime() * 1000 > new Date().getTime();

  return isPaid && isPeriodValid;
}

// ! Snippets don't export before adjusting

// snippet
// if in userProviderSubscription id from collection !== with in stripe saved subscriptionId ? then adjust it
export async function adjustUserProviderSubscription() {
  const customer = await stripe.customers.retrieve(token.stripe_customerID, { expand: ['subscriptions'] });
  if (customer?.subscriptions?.data?.length >= 1) {
    if (
      customer?.subscriptions?.data?.length == 1 &&
      customer.subscriptions.data[0]?.id !== token.userProviderSubscription.id
    ) {
      const newUser = await User.findByIdAndUpdate(
        token.id,
        {
          userProviderSubscription: {
            id: customer.subscriptions.data[0]?.id,
            priceId: customer.subscriptions.data[0]?.plan?.id,
            status: customer.subscriptions.data[0]?.status == 'active' ? 'paid' : 'unpaid',
            currency: customer.subscriptions.data[0]?.plan?.currency,
            interval: customer.subscriptions.data[0]?.plan?.interval,
            intervalCount: customer.subscriptions.data[0]?.plan?.interval_count,
            createdAt: customer.subscriptions?.data[0]?.created,
            periodStartsAt: customer.subscriptions?.data[0]?.current_period_start,
            periodEndsAt: customer.subscriptions?.data[0]?.current_period_end,
          },
        },
        { returnDocument: 'after', lean: true }
      );
    }
  }
  return;
}

// snippet
// update all users with wrong stripe email
export async function updateAllStripeUserswithWrongEmail(allUsers, stripe = undefined) {
  // const stripe = new Stripe(process.env.STRIPE_SECRET_KEY);
  const users = allUsers;

  // let differentEmailUsers = [];
  for (let user of users) {
    const stripeCustomer = user.stripe_customer;
    const customerData = await stripe.customers.retrieve(stripeCustomer);

    if (user.email !== customerData.email) {
      // differentEmailUsers = [
      //   ...differentEmailUsers,
      //   { userid: user._id, stripeid: user.stripe_customer, email: user.email, stripeemail: customerData.email },
      // ];
      const customer = await stripe.customers.update(stripeCustomer, {
        email: user.email,
      });
    }
  }
}

export const sendMessageToOpenAi = async (messages) => {
  try {
    const response = await fetch('/api/dashboard/admin/openaiSeeding', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ messages }),
    });
    return await response.json();
  } catch (error) {
    console.error('utils err', error);
  }
};
export const sendQuestionToOpenAi = async (messages) => {
  try {
    const response = await fetch('/api/dashboard/admin/openaiQuestion', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ messages }),
    });
    return await response.json();
  } catch (error) {
    console.error('utils err', error);
  }
};
