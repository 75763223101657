// #0F3C69
export default function Logo(props) {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 204 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M50.5039 34.5391C49.1602 34.5391 48 34.2656 47.0234 33.7188C46.0469 33.1719 45.2969 32.3945 44.7734 31.3867C44.2578 30.3711 44 29.1719 44 27.7891C44 26.4453 44.2578 25.2656 44.7734 24.25C45.2969 23.2344 46.0312 22.4414 46.9766 21.8711C47.9297 21.3008 49.0469 21.0156 50.3281 21.0156C51.1953 21.0156 52.0039 21.1562 52.7539 21.4375C53.5039 21.7109 54.1602 22.125 54.7227 22.6797C55.2852 23.2266 55.7227 23.918 56.0352 24.7539C56.3477 25.582 56.5039 26.5547 56.5039 27.6719V28.6562H45.4414V26.418H54.7461L53.0586 27.0039C53.0586 26.3398 52.957 25.7617 52.7539 25.2695C52.5508 24.7773 52.2461 24.3984 51.8398 24.1328C51.4414 23.8594 50.9453 23.7227 50.3516 23.7227C49.7578 23.7227 49.2539 23.8594 48.8398 24.1328C48.4336 24.4062 48.1211 24.7773 47.9023 25.2461C47.6836 25.7148 47.5742 26.2539 47.5742 26.8633V28.4453C47.5742 29.1562 47.6992 29.7656 47.9492 30.2734C48.1992 30.7734 48.5469 31.1602 48.9922 31.4336C49.4453 31.6992 49.9648 31.832 50.5508 31.832C50.957 31.832 51.3242 31.7734 51.6523 31.6562C51.9805 31.5391 52.2617 31.3672 52.4961 31.1406C52.7383 30.9141 52.918 30.6406 53.0352 30.3203L56.3867 30.543C56.2227 31.3477 55.8789 32.0508 55.3555 32.6523C54.8398 33.2461 54.1719 33.7109 53.3516 34.0469C52.5312 34.375 51.582 34.5391 50.5039 34.5391Z"
        fill={`${props.color ? props.color : '#113E6A'}`}
      />
      <path
        d="M68.9375 24.918L65.6211 25.1172C65.5352 24.7031 65.3047 24.3477 64.9297 24.0508C64.5547 23.7461 64.0508 23.5938 63.418 23.5938C62.8555 23.5938 62.3828 23.7109 62 23.9453C61.625 24.1797 61.4375 24.4922 61.4375 24.8828C61.4375 25.1953 61.5586 25.4648 61.8008 25.6914C62.0508 25.9102 62.4766 26.082 63.0781 26.207L65.457 26.6758C66.7305 26.9336 67.6797 27.3516 68.3047 27.9297C68.9297 28.5078 69.2422 29.2695 69.2422 30.2148C69.2422 31.082 68.9922 31.8398 68.4922 32.4883C67.9922 33.1289 67.3047 33.6328 66.4297 34C65.5547 34.3594 64.5508 34.5391 63.418 34.5391C61.6836 34.5391 60.3008 34.1758 59.2695 33.4492C58.2461 32.7227 57.6484 31.7383 57.4766 30.4961L61.0273 30.3086C61.1445 30.8398 61.4102 31.2422 61.8242 31.5156C62.2383 31.7891 62.7695 31.9258 63.418 31.9258C64.0508 31.9258 64.5625 31.8008 64.9531 31.5508C65.3516 31.3008 65.5508 30.9844 65.5508 30.6016C65.5508 29.9531 64.9883 29.5156 63.8633 29.2891L61.6016 28.8203C60.3281 28.5625 59.3789 28.1211 58.7539 27.4961C58.1289 26.8711 57.8164 26.0703 57.8164 25.0938C57.8164 24.25 58.0469 23.5234 58.5078 22.9141C58.9688 22.3047 59.6133 21.8359 60.4414 21.5078C61.2773 21.1797 62.2539 21.0156 63.3711 21.0156C65.0273 21.0156 66.3281 21.3672 67.2734 22.0703C68.2266 22.7656 68.7812 23.7148 68.9375 24.918Z"
        fill={`${props.color ? props.color : '#113E6A'}`}
      />
      <path
        d="M77.8438 21.1797V23.9219H69.7695V21.1797H77.8438ZM71.6211 34.2812V20.2305C71.6211 19.2852 71.8047 18.5 72.1719 17.875C72.5469 17.25 73.0547 16.7812 73.6953 16.4688C74.3438 16.1562 75.0781 16 75.8984 16C76.4609 16 76.9727 16.0469 77.4336 16.1406C77.9023 16.2266 78.2461 16.3008 78.4648 16.3633L77.832 19.082C77.6836 19.043 77.5078 19.0039 77.3047 18.9648C77.1016 18.9258 76.8867 18.9062 76.6602 18.9062C76.1289 18.9062 75.7578 19.0312 75.5469 19.2812C75.3359 19.5234 75.2305 19.8672 75.2305 20.3125V34.2812H71.6211Z"
        fill={`${props.color ? props.color : '#113E6A'}`}
      />
      <path
        d="M82.5547 34.5273C81.7266 34.5273 80.9805 34.3828 80.3164 34.0938C79.6602 33.7969 79.1445 33.3594 78.7695 32.7812C78.3945 32.2031 78.207 31.4844 78.207 30.625C78.207 29.8906 78.3398 29.2812 78.6055 28.7969C78.8711 28.3047 79.2344 27.9062 79.6953 27.6016C80.1562 27.2969 80.6797 27.0664 81.2656 26.9102C81.8516 26.7539 82.4727 26.6445 83.1289 26.582C83.8867 26.5117 84.4961 26.4414 84.957 26.3711C85.418 26.3008 85.7539 26.1953 85.9648 26.0547C86.1836 25.9062 86.293 25.6953 86.293 25.4219V25.3633C86.293 24.8242 86.1211 24.4062 85.7773 24.1094C85.4414 23.8125 84.9648 23.6641 84.3477 23.6641C83.6992 23.6641 83.1797 23.8086 82.7891 24.0977C82.4062 24.3789 82.1523 24.7383 82.0273 25.1758L78.6641 24.9062C78.8359 24.1094 79.1719 23.4219 79.6719 22.8438C80.1719 22.2578 80.8164 21.8086 81.6055 21.4961C82.4023 21.1758 83.3242 21.0156 84.3711 21.0156C85.0977 21.0156 85.793 21.1016 86.457 21.2734C87.1211 21.4453 87.7109 21.7109 88.2266 22.0703C88.75 22.4219 89.1641 22.8789 89.4688 23.4414C89.7734 24.0039 89.9258 24.6719 89.9258 25.4453V34.2812H86.4688V32.4531H86.375C86.1641 32.8672 85.8828 33.2305 85.5312 33.543C85.1797 33.8477 84.7578 34.0898 84.2656 34.2695C83.7734 34.4414 83.2031 34.5273 82.5547 34.5273ZM83.5977 32.0195C84.1289 32.0195 84.5977 31.9141 85.0039 31.7031C85.418 31.4844 85.7383 31.1953 85.9648 30.8359C86.1992 30.4688 86.3164 30.0586 86.3164 29.6055V28.2109C86.207 28.2812 86.0508 28.3477 85.8477 28.4102C85.6523 28.4727 85.4336 28.5273 85.1914 28.5742C84.9492 28.6211 84.707 28.6641 84.4648 28.7031C84.2227 28.7344 84.0039 28.7656 83.8086 28.7969C83.3789 28.8594 83.0039 28.9609 82.6836 29.1016C82.3633 29.2344 82.1133 29.418 81.9336 29.6523C81.7617 29.8789 81.6758 30.1641 81.6758 30.5078C81.6758 31 81.8555 31.375 82.2148 31.6328C82.5742 31.8906 83.0352 32.0195 83.5977 32.0195Z"
        fill={`${props.color ? props.color : '#113E6A'}`}
      />
      <path
        d="M92.0469 34.2812V21.1797H95.5508V23.4766H95.6914C95.9336 22.6641 96.3359 22.0508 96.8984 21.6367C97.4609 21.2148 98.1055 21.0039 98.832 21.0039C99.0195 21.0039 99.2148 21.0156 99.418 21.0391C99.6289 21.0547 99.8164 21.0859 99.9805 21.1328V24.3438C99.8086 24.2891 99.5703 24.2461 99.2656 24.2148C98.9688 24.1758 98.6914 24.1562 98.4336 24.1562C97.9102 24.1562 97.4375 24.2734 97.0156 24.5078C96.5938 24.7344 96.2617 25.0547 96.0195 25.4688C95.7852 25.875 95.668 26.3438 95.668 26.875V34.2812H92.0469Z"
        fill={`${props.color ? props.color : '#113E6A'}`}
      />
      <path
        d="M104.422 34.5273C103.594 34.5273 102.848 34.3828 102.184 34.0938C101.527 33.7969 101.012 33.3594 100.637 32.7812C100.262 32.2031 100.074 31.4844 100.074 30.625C100.074 29.8906 100.207 29.2812 100.473 28.7969C100.738 28.3047 101.102 27.9062 101.562 27.6016C102.023 27.2969 102.547 27.0664 103.133 26.9102C103.719 26.7539 104.34 26.6445 104.996 26.582C105.754 26.5117 106.363 26.4414 106.824 26.3711C107.285 26.3008 107.621 26.1953 107.832 26.0547C108.051 25.9062 108.16 25.6953 108.16 25.4219V25.3633C108.16 24.8242 107.988 24.4062 107.645 24.1094C107.309 23.8125 106.832 23.6641 106.215 23.6641C105.566 23.6641 105.047 23.8086 104.656 24.0977C104.273 24.3789 104.02 24.7383 103.895 25.1758L100.531 24.9062C100.703 24.1094 101.039 23.4219 101.539 22.8438C102.039 22.2578 102.684 21.8086 103.473 21.4961C104.27 21.1758 105.191 21.0156 106.238 21.0156C106.965 21.0156 107.66 21.1016 108.324 21.2734C108.988 21.4453 109.578 21.7109 110.094 22.0703C110.617 22.4219 111.031 22.8789 111.336 23.4414C111.641 24.0039 111.793 24.6719 111.793 25.4453V34.2812H108.336V32.4531H108.242C108.031 32.8672 107.75 33.2305 107.398 33.543C107.047 33.8477 106.625 34.0898 106.133 34.2695C105.641 34.4414 105.07 34.5273 104.422 34.5273ZM105.465 32.0195C105.996 32.0195 106.465 31.9141 106.871 31.7031C107.285 31.4844 107.605 31.1953 107.832 30.8359C108.066 30.4688 108.184 30.0586 108.184 29.6055V28.2109C108.074 28.2812 107.918 28.3477 107.715 28.4102C107.52 28.4727 107.301 28.5273 107.059 28.5742C106.816 28.6211 106.574 28.6641 106.332 28.7031C106.09 28.7344 105.871 28.7656 105.676 28.7969C105.246 28.8594 104.871 28.9609 104.551 29.1016C104.23 29.2344 103.98 29.418 103.801 29.6523C103.629 29.8789 103.543 30.1641 103.543 30.5078C103.543 31 103.723 31.375 104.082 31.6328C104.441 31.8906 104.902 32.0195 105.465 32.0195Z"
        fill={`${props.color ? props.color : '#113E6A'}`}
      />
      <path
        d="M124.789 24.918L121.473 25.1172C121.387 24.7031 121.156 24.3477 120.781 24.0508C120.406 23.7461 119.902 23.5938 119.27 23.5938C118.707 23.5938 118.234 23.7109 117.852 23.9453C117.477 24.1797 117.289 24.4922 117.289 24.8828C117.289 25.1953 117.41 25.4648 117.652 25.6914C117.902 25.9102 118.328 26.082 118.93 26.207L121.309 26.6758C122.582 26.9336 123.531 27.3516 124.156 27.9297C124.781 28.5078 125.094 29.2695 125.094 30.2148C125.094 31.082 124.844 31.8398 124.344 32.4883C123.844 33.1289 123.156 33.6328 122.281 34C121.406 34.3594 120.402 34.5391 119.27 34.5391C117.535 34.5391 116.152 34.1758 115.121 33.4492C114.098 32.7227 113.5 31.7383 113.328 30.4961L116.879 30.3086C116.996 30.8398 117.262 31.2422 117.676 31.5156C118.09 31.7891 118.621 31.9258 119.27 31.9258C119.902 31.9258 120.414 31.8008 120.805 31.5508C121.203 31.3008 121.402 30.9844 121.402 30.6016C121.402 29.9531 120.84 29.5156 119.715 29.2891L117.453 28.8203C116.18 28.5625 115.23 28.1211 114.605 27.4961C113.98 26.8711 113.668 26.0703 113.668 25.0938C113.668 24.25 113.898 23.5234 114.359 22.9141C114.82 22.3047 115.465 21.8359 116.293 21.5078C117.129 21.1797 118.105 21.0156 119.223 21.0156C120.879 21.0156 122.18 21.3672 123.125 22.0703C124.078 22.7656 124.633 23.7148 124.789 24.918Z"
        fill={`${props.color ? props.color : '#113E6A'}`}
      />
      <path
        d="M129.207 34.5039C128.629 34.5039 128.141 34.3086 127.742 33.918C127.344 33.5273 127.145 33.043 127.145 32.4648C127.145 31.8867 127.344 31.4023 127.742 31.0117C128.141 30.6211 128.629 30.4258 129.207 30.4258C129.785 30.4258 130.273 30.6211 130.672 31.0117C131.07 31.4023 131.27 31.8867 131.27 32.4648C131.27 33.043 131.07 33.5273 130.672 33.918C130.273 34.3086 129.785 34.5039 129.207 34.5039Z"
        fill={`${props.color ? props.color : '#113E6A'}`}
      />
      <path
        d="M26.1871 0.0500488C25.3834 0.0801814 23.8177 0.150587 21.8549 0.834655C21.3737 1.00241 20.869 1.20668 20.3454 1.45643C20.4171 2.52527 20.5652 4.74088 20.6358 5.79755C9.13838 10.502 9.59918 21.7194 13.0078 26.4436C10.995 29.2662 10.0809 32.577 10 35.6097C10.0663 36.7811 10.4972 41.1663 15.9997 41.2998C15.9997 41.2998 30.0212 40.0615 32.2749 26.8394C32.4588 25.3226 32.7563 19.1382 25.2996 19.7539C25.3564 19.6626 25.4161 19.5661 25.4795 19.4644C34.7169 7.68171 28.9268 4.55777 26.1865 4.51315C24.7292 4.62499 23.3813 4.89357 22.1507 5.26618L22.0233 3.35855C23.4935 2.88193 25.4064 2.41893 27.5571 2.28189C28.7062 2.37113 34.3631 1.79108 34.9378 10.8508C34.9378 10.8508 36.3081 0.71992 26.1868 0.0503386L26.1871 0.0500488ZM13.4238 25.8876C13.365 25.8323 13.303 25.774 13.2376 25.7126C11.7789 24.0168 11.2487 18.9287 12.7071 15.7601C15.098 11.419 18.2302 9.22745 20.7913 8.12356L21.6173 20.4704C17.9525 21.556 15.277 23.5233 13.4238 25.8879V25.8876ZM25.3914 7.01271C31.4776 7.20944 25.3731 17.5261 23.8573 19.9434C23.6252 19.9842 23.379 20.0323 23.1426 20.0833L22.3051 7.56639C23.9241 7.06834 25.1123 7.01271 25.3914 7.01271Z"
        fill={`${props.color ? props.color : '#113E6A'}`}
      />
    </svg>
  );
}
