export { default as Icon1 } from './Primary/icon-01.svg';
export { default as Icon2 } from './Primary/icon-02.svg';
export { default as Icon3 } from './Primary/icon-03.svg';
export { default as Icon4 } from './Primary/icon-04.svg';
export { default as Icon5 } from './Primary/icon-05.svg';
export { default as Icon6 } from './Primary/icon-06.svg';
export { default as Icon7 } from './Primary/icon-07.svg';
export { default as Icon8 } from './Primary/icon-08.svg';
export { default as Icon9 } from './Primary/icon-09.svg';
export { default as Icon10 } from './Primary/icon-10.svg';
export { default as Icon11 } from './Primary/icon-11.svg';
export { default as Icon12 } from './Primary/icon-12.svg';
export { default as Icon13 } from './Primary/icon-13.svg';
export { default as Icon14 } from './Primary/icon-14.svg';
export { default as Icon15 } from './Primary/icon-15.svg';
export { default as Icon16 } from './Primary/icon-16.svg';
export { default as Icon17 } from './Primary/icon-17.svg';
export { default as Icon18 } from './Primary/icon-18.svg';
export { default as Icon19 } from './Primary/icon-19.svg';
export { default as Icon20 } from './Primary/icon-20.svg';
export { default as Icon21 } from './Primary/icon-21.svg';
export { default as Icon22 } from './Primary/icon-22.svg';
export { default as Icon23 } from './Primary/icon-23.svg';
export { default as Icon24 } from './Primary/icon-24.svg';
export { default as Logo } from './logo-single.svg';
export { default as LogoFull } from './logo-fulllength.svg';
export { default as IconBanner } from './HomepageBannerIcon.svg';
export { default as Category1 } from './Category/-_Recording.svg';
export { default as Category2 } from './Category/-_Mixing.svg';
export { default as Category3 } from './Category/-_Mastering.svg';
export { default as Category4 } from './Category/-_Musicproduction.svg';
export { default as Category5 } from './Category/-_Rent recording studio.svg';
export { default as Category6 } from './Category/-_Podcasts & Audiobooks.svg';
export { default as Category7 } from './Category/-_Rent rehearsal room.svg';
export { default as Category8 } from './Category/-_Music lessons.svg';
export { default as Category9 } from './Category/-_Other.svg';
export { default as Category10 } from './Category/-_Musicproduction & Songwriting.svg';
export { default as Category11 } from './Category/-_Audio Engineering & Post production.svg';
export { default as Category12 } from './Category/-_Voice Over & Streaming.svg';
export { default as Category13 } from './Category/-_Lessons & Transcriptions -.svg';
export { default as Illustration1 } from './Illustrations/Anbieter-Howto1.svg';
